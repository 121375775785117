<template>
  <div class="main">
    <agent-info-comp></agent-info-comp>

    <agent-sub-title>환전신청</agent-sub-title>

    <div class="exchange_panel">
      <div class="d1">

        <el-table
            :data="selfExchangeList"
            style="width: 100%"
            max-height="860"
            border>
          <el-table-column
              label="날짜"
              width="220">
            <template slot-scope="scope">
              {{ scope.row.createTime|datef('MM-DD HH:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column
              label="금액"
              width="220">
            <template slot-scope="scope">
              {{ scope.row.amount|comma }}
            </template>
          </el-table-column>


          <el-table-column
              label="상태"
              width="220">
            <template slot-scope="scope">
              <span v-if="scope.row.status == agentConst.ApplyCash.STATUS_WAITING">미확인</span>
              <span v-if="scope.row.status == agentConst.ApplyCash.STATUS_CHECKING">확인중</span>
              <span v-if="scope.row.status == agentConst.ApplyCash.STATUS_COMPLITE"
                    style="color: deepskyblue">완료</span>
              <span v-if="scope.row.status == agentConst.ApplyCash.STATUS_CANCEL"
                    style="color: red">실패</span>
            </template>
          </el-table-column>

        </el-table>
        <div style="margin-top: 20px">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="10"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageTotal">
          </el-pagination>
        </div>
      </div>
      <div class="d2">
        <div class="item">
          <div class="ileft">
            보유금액
          </div>
          <div class="iright">
            {{ $store.state.agent.agentInfo.cash|comma }}
          </div>
        </div>
        <div class="item">
          <div class="ileft">
            은행
          </div>
          <div class="iright">
            {{ $store.state.agent.agentInfo.bank }}
          </div>
        </div>
        <div class="item">
          <div class="ileft">
            예금주
          </div>
          <div class="iright">
            {{ $store.state.agent.agentInfo.beneficiary }}
          </div>
        </div>
        <div class="item">
          <div class="ileft">
            환전금액
          </div>
          <div class="iright">
            <el-input size="mini" v-model="applyCash.amount"></el-input>
          </div>
        </div>
        <div class="item">
          <div class="ileft">
            환전비밀번호
          </div>
          <div class="iright">
            <el-input size="mini" v-model="applyCash.exchangePasswd"></el-input>
          </div>
        </div>
        <div class="item">
          <div class="ileft">

          </div>
          <div class="iright">
            <el-button size="mini" type="success" @click="exchangenow()">출금하기</el-button>
          </div>
        </div>
      </div>


    </div>


  </div>

</template>

<script>

import {Loading} from 'element-ui';
import AgentSubTitle from "../../components/agent/AgentSubTitle";
import {agentMixin} from "../../common/agent/agentMixin";
import {
  agentExchangeCash,
  doLogin,
  getAgentById,
  moveagentmoney,
  selfExchangeHistory
} from "../../network/agent/commonRequest";
import AgentInfoComp from "../../components/agent/AgentInfoComp";

export default {
  name: "AgentExchangeCash",
  components: {
    AgentInfoComp,
    AgentSubTitle,
  },
  mixins: [agentMixin],
  data() {
    return {
      applyCash: {},
      fullscreenLoading: false,
      selfExchangeList: [],
      pageNum: 1,
      pageSize: 10,
      pageTotal: 0,
    }
  },
  methods: {
    exchangenow() {
      this.fullscreenLoading = true;
      agentExchangeCash(this.applyCash).then(res => {
        this.fullscreenLoading = false;
        if (res.data.success) {
          this.$message.success("출금신청이 완료되였습니다");
          this.applyCash = {};
          getAgentById(-1).then(res => {
            this.$store.state.agent.agentInfo = res.data.data;
          })
          this.getSelfExchangeList();
        } else {
          this.$message.error(res.data.msg);
        }
      })

    },
    getSelfExchangeList() {
      selfExchangeHistory(this.pageNum, this.pageSize).then(res => {
        this.pageTotal = res.data.total
        this.selfExchangeList = res.data.data;
      })
    },
    handleSizeChange(val) {
      this.pageNum = 1;
      this.pageSize = val;
      this.getSelfExchangeList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getSelfExchangeList();
    },
  },
  created() {
    this.getSelfExchangeList()
  }
}
</script>

<style scoped>

.exchange_panel {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #c2dbfd;
  flex-wrap: nowrap;
}

.exchange_panel .d1 {
  width: 50%;
  height: 300px;

}

.exchange_panel .d2 {
  width: 50%;
  border-left: 1px solid #e5e5e8;
  height: 300px;
}

.exchange_panel .item {
  width: 50%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  height: 40px;
  color: #010101;
}

.item .ileft {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

}

.item .iright {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}


</style>